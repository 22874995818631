import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Footer from '../../components/Footer';
import ImagesContainerFullWidth from '../../components/ImagesContainerFullWidth';
import FullWidthImage from '../../components/FullWidthImage';
import research from '../../assets/branch-research.jpg';
import workshop from '../../assets/branch-workshop.jpg';
import wireframe from '../../assets/branch-wireframe.jpg';
import atm from '../../assets/branch-atm.jpg';
import flow1 from '../../assets/branch-flow1.jpg';
import flow2 from '../../assets/branch-flow2.jpg';
import './styles.scss';

function BranchTransformation({ history }) {
  useEffect(() => {
    if (Cookies.get('isLogged') !== 'yeap') {
      history.push('/');
    }
  });

  return (
    <div className="BranchTransformation">
      <div className="first-info container-slim">
        <div className="UI-subtitle">Consultancy</div>

        <h2>
          Branch
          <br />
          Transformation
        </h2>

        <div className="description UI-highlight">
          A major financial service company from Argentina started a few years ago a business
          transformation that was reflected in the launch of a rebrand and the impulse of its
          digital channels. In this context it became evident the need for assessing the status of
          its physical branches and transforming them alongside.
        </div>

        <div className="BranchTransformation__disclaimer UI-caption">
          To comply with non-disclosure agreements, I have omitted specific details and confidential
          information.
        </div>
      </div>

      <div className="hero-wrapper">
        <div className="container-slim">
          <div className="hero" />
        </div>
      </div>

      <div className="container-slim">
        <div className="data">
          <div className="left">
            <div className="label UI-subtitle">Design Team*</div>

            <div className="value UI-small2">Research</div>
            <div className="value UI-small1">
              1 Head of design
              <br />2 UX designers
            </div>

            <div className="value UI-small2 --scrum-team">Scrum team</div>
            <div className="value UI-small1">
              2 UX designers
              <br /> 1 UI designer
            </div>

            <div className="label UI-subtitle --year">Year</div>

            <div className="value UI-small1">2018 - 2019</div>
          </div>

          <div className="right">
            <div className="UI-subtitle">My role</div>

            <div className="value UI-body">UX Designer</div>

            <div className="value UI-body">
              During the initial phases I worked on <b>user research</b> and <b>user analysis</b>,
              and assisted on <b>workshops</b>. Afterward, I worked on redesigning the{' '}
              <b>web-app</b> used by clerks on branches and designing the new <b>ATM’s software</b>.
            </div>

            <div className="tasks UI-small2">
              Contextual inquiry research, Affinity diagrams, Personas, Customer journey maps,
              User-flows, Wireframing, Prototyping, User Testing.
            </div>
          </div>
        </div>

        <div className="BranchTransformation__disclaimer-data UI-caption">
          * This project was a joint effort from different teams like change management, processes
          and protocol, architechture, development, etc.
        </div>
      </div>

      <div className="wrapper --grey">
        <div className="container-slim">
          <h3>Gaining understanding</h3>

          <p className="UI-body">
            The first step of our process was <b>research</b>. We started by interviewing the
            company’s <b>stakeholders</b> in order to fully understand the business needs, and
            followed with visits to key branches spread out around the country to live its everyday
            reality. During the visits we used the contextual inquiry method, observing and
            interviewing both <b>clients and clerks</b>.
          </p>

          <FullWidthImage
            image={research}
            disclaimer="Outside of a branch during payment due date."
          />

          <p className="UI-body">
            With all the data gathered, we went on to analyzing it with the affinity diagram method.
            Then we shared the results through the{' '}
            <b>creation of personas and customer & employee journey maps</b>.
          </p>

          <h4 className="--some-important">Some important insights</h4>

          <div className="boxes --some-important --no-bottom-margin">
            <div className="boxes__box">
              <h5 className="boxes__title">
                The main operation done in branches is payment in cash
              </h5>

              <div className="boxes__description UI-small1">
                …of credit card statements. The days surrounding payment due date, the branches get
                full, with waiting lines reaching the sidewalk. The rest of the month, branches have
                a considerable amount of unused space.
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">Self-service machines are used mainly for printing</h5>

              <div className="boxes__description UI-small1">
                …the credit card statement before going to the teller line
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">
                Customers usually go through 3 different waiting lines in order to finalize a whole
                operation
              </h5>

              <div className="boxes__description UI-small1">
                1) self-service machine for printing the statement
                <br />
                2) teller line for paying the statement
                <br />
                3) customer service for consulting the updated available credit
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">
                A substantial part of the customers have a low technology level.
              </h5>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="container-slim">
          <h3>Co-ideating</h3>

          <p className="UI-body">
            With all the insights and design tools in hand,{' '}
            <b>4 multidisciplinary & co-ideation workshops</b> took place. During this time I
            assisted the facilitator in the task of moderating the teams. In the first workshop
            participants worked on identifying the main pain points of the customers and employees
            to later ideate and prioritize solutions together. The subsequent workshops focused on
            deepening said solutions.
          </p>

          <FullWidthImage
            image={workshop}
            disclaimer="Workshop's participants working with the personas and the customer journeys maps."
          />

          <h4>The solution: a pilot branch</h4>

          <p className="UI-body">
            The solution agreed was to{' '}
            <b>build a pilot branch in which to test, during 3 months, a new model</b>. Some of its
            characteristics:
          </p>

          <div className="boxes --pilot --no-bottom-margin">
            <div className="boxes__box">
              <h5 className="boxes__title">
                Focus on client education and its migration to digital channels
              </h5>

              <div className="boxes__description UI-small1">
                …in order to reduce the number of cash transactions and be able to turn the branch
                into an experience, sales and advisory center.
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">A closer and more effective customer service</h5>

              <div className="boxes__description UI-small1">
                …in which the clerk no longer sits behind a desk but assists the client with a
                tablet, next to him
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">An enhanced self-service area</h5>

              <div className="boxes__description UI-small1">
                …with machines with more functionalities, to migrate users with lower technology
                adoption
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">A new web-app for customer service</h5>

              <div className="boxes__description UI-small1">
                …that works well within a tablet and the new “shoulder to shoulder” model
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">The addition of a virtual waiting line system</h5>

              <div className="boxes__description UI-small1">
                …to help organize clients and give them a better experience
              </div>
            </div>

            <div className="boxes__box" />
          </div>
        </div>
      </div>

      <div className="wrapper --grey">
        <div className="container-slim">
          <h3>Let’s start creating!</h3>

          <p className="UI-body">
            To tackle the technology needs, the client created a <b>Scrum team</b> to develop the
            MVPs of the web-app and the ATM’s software. As I had been in the project from the
            beginning and had a comprehensive view, I was invited to join the team as its UX
            Designer. My objective was to work on the MVPs until release, and then return to my
            original team in time for the pilot’s launch. During this 6 months period I worked
            together with the team’s UI Designer, and for the last two months, next to the client’s
            UX Designer selected to give continuity to the work.
          </p>

          <p className="UI-body">
            We created and discussed different <b>user-flows</b> and <b>sketches</b>, built rapid{' '}
            <b>prototypes</b> and <b>tested them with final users</b>. We repeated this process many
            times, with different screens and features, to validate designs and find (and fix) any
            issues as quickly as possible.
          </p>

          <ImagesContainerFullWidth
            images={[flow1, flow2]}
            disclaimer="User-flows of the virtual waiting line."
          />

          <h4>The web-app</h4>

          <ul>
            <li>
              <p className="UI-body">
                The web-app was no longer an internal-use-only app. The new “shoulder to shoulder”
                model implied sharing the screen with the customer. With this in mind we{' '}
                <b>aligned its look and feel to the rest of the digital channels</b> (website,
                homebanking, personal app) to provide a cohesive user experience. We also{' '}
                <b>changed all the internal jargon for customer-friendly messages</b>.
              </p>
            </li>

            <li>
              <p className="UI-body">
                In the previous web-app, when accessing to a <b>customer account</b>, the main
                screen showed only payment info. All other important info was several clicks away.
                We redesigned that screen <b>to serve as a customer overview</b>, showing not only
                payment info, but also products and customer’s digitalization data, helping clerks
                to be more effective in their service and their sale and migration objectives.
              </p>
            </li>

            <li>
              <p className="UI-body">
                The biggest new feature we added was the <b>virtual waiting line</b>. To create a
                smooth waiting process, besides informing waiting times, we decided to give
                customers the option to get a <b>SMS notification</b> alerting them when their turn
                was close. With this we had a double purpose: to allow clients to leave the branch
                and use their time more efficiently, and to help decongest the place.
              </p>
            </li>
          </ul>

          <FullWidthImage
            className="--wireframe"
            image={wireframe}
            disclaimer="Wireframe of customer account’s main screen."
          />

          <h4>The ATM</h4>

          <div className="ATM">
            <div className="left">
              <ul>
                <li>
                  <p className="UI-body">
                    As we already did with the web-app, in order to provide a cohesive user
                    experience we{' '}
                    <b>aligned the ATM’s look and feel to the rest of the digital channels</b>. In
                    this case the task proved to be harder since the device and its context of use
                    was very different to what we were used to. We did some{' '}
                    <b>rapid benchmarking and contextual observation</b> to help us with the design
                    decisions.
                  </p>
                </li>

                <li>
                  <p className="UI-body">
                    To help migrate users with lower technology adoption to ATMs, we added the{' '}
                    <b>option to pay in cash or with debit card</b>. Since there was a development
                    time constraint, to speed it up we were asked to reuse an old flow that was
                    already programmed, which only left us with the option to improve the visual
                    design. We improved animations and the content that assists the user along the
                    process.
                  </p>
                </li>

                <li>
                  <p className="UI-body">
                    To reduce the printing of the <b>credit card statement</b>, we made its consult
                    <b>available on the ATM</b> and gave the option to send it over e-mail.
                  </p>
                </li>
              </ul>
            </div>

            <div className="right">
              <img src={atm} alt="ATM" />
              <div className="ATM__disclaimer UI-caption">ATM's prototype.</div>
            </div>
            <div className="ATM__disclaimer-mobile UI-caption">ATM's prototype.</div>
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="container-slim">
          <h3>These days</h3>

          <p className="UI-body">
            We just <b>released the MVPs</b> and the launch of the pilot branch is around the
            corner. I’m currently <b>helping in the trainings of the clerks</b> that will be
            participating in the pilot and trying to make them feel confident in their new role.
            During these trainings, by using the app frequently and in more realistic situations, I{' '}
            <b>have been able to identify new design issues that have been added to the backlog</b>.
            I already said good-bye to the Scrum team and I’m preparing for the work that I’ll be
            doing during the pilot: observing the functioning of the branch as a whole, measuring
            KPIs and making adjusts where needed.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default BranchTransformation;
