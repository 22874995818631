import React, { Component } from 'react';
import { BrowserRouter, Switch, Route, withRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import Header from './components/Header';
import Home from './pages/Home';
import Portfolio from './pages/Portfolio';
import NoFilter from './pages/NoFilter';
import BranchTransformation from './pages/BranchTransformation';
import Nutrition from './pages/Nutrition';
import './styles/base.scss';

ReactGA.initialize('UA-141357224-1');

const withAnalytics = Component => props => {
  ReactGA.pageview(window.location.pathname + window.location.search);
  return <Component {...props} />;
};

const ScrollToTop = withRouter(props => {
  window.scrollTo(0, 0);
  return props.children;
});

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <ScrollToTop>
          <Route path="/" component={Header} />

          <Switch>
            <Route path="/portfolio/nofilter" component={withAnalytics(NoFilter)} />
            <Route
              path="/portfolio/branch-transformation"
              component={withAnalytics(BranchTransformation)}
            />
            <Route path="/portfolio/nutripi" component={withAnalytics(Nutrition)} />
            <Route path="/portfolio" component={withAnalytics(Portfolio)} />
            <Route path="/" component={withAnalytics(Home)} />
          </Switch>
        </ScrollToTop>
      </BrowserRouter>
    );
  }
}

export default App;
