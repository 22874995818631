import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Footer from '../../components/Footer';
import ImagesContainerFullWidth from '../../components/ImagesContainerFullWidth';
import FourPhotos from '../../components/FourPhotos';
import Box from '../../components/Box';
import BoxFull from '../../components/BoxFull';
import Video from '../../components/Video';
import FullWidthImage from '../../components/FullWidthImage';
import ThreeBox from '../../components/ThreeBox';
import chartPhoto from '../../assets/nofilter-chart-photography.jpg';
import chartTravel from '../../assets/nofilter-chart-travel.jpg';
import personaLore from '../../assets/nofilter-proto-persona-lore.png';
import personaJan from '../../assets/nofilter-proto-personna-jan.png';
import brainstorming from '../../assets/nofilter-brainstorming.jpg';
import prototype from '../../assets/nofilter-prototype.jpg';
import mobileWeb from '../../assets/nofilter-mobile-web.png';
import flow1 from '../../assets/nofilter-flow1.png';
import flow2 from '../../assets/nofilter-flow2.jpg';
import final from '../../assets/nofilter-final-designs.png';
import ds1 from '../../assets/nofilter-colors.png';
import ds2 from '../../assets/nofilter-typography.png';
import ds3 from '../../assets/nofilter-text-fields.png';
import ds4 from '../../assets/nofilter-buttons-light.png';
import ds5 from '../../assets/nofilter-buttons-dark.png';
import ds6 from '../../assets/nofilter-messages.png';
import ds7 from '../../assets/nofilter-navigation.png';
import ds8 from '../../assets/nofilter-list.png';
import uploadImage from '../../assets/nofilter-uploader.png';
import uploadVideo from '../../assets/nofilter-upload.mp4';
import swipeImage from '../../assets/nofilter-carousel.jpg';
import swipeVideo from '../../assets/nofilter-swipe.mp4';
import socialImage from '../../assets/nofilter-social.png';
import socialVideo from '../../assets/nofilter-social.mp4';
import registerOld1 from '../../assets/nofilter-register-old1.jpg';
import registerOld2 from '../../assets/nofilter-register-old2.jpg';
import registerNew1 from '../../assets/nofilter-register-new1.jpg';
import registerNew2 from '../../assets/nofilter-register-new2.jpg';
import './styles.scss';

function NoFilter({ history }) {
  useEffect(() => {
    if (Cookies.get('isLogged') !== 'yeap') {
      history.push('/');
    }
  });

  return (
    <div className="NoFilter">
      <div className="first-info container-slim">
        <div className="UI-subtitle">Personal project</div>

        <h2>
          NoFilter
          <br />
          App
        </h2>

        <div className="description UI-highlight">
          What if there was an easy way to not only discover great photo spots but to also find out
          the exact location of where the picture was taken? And what if you could also save those
          photo locations, for creative inspiration or to spark future trips, and keep a log of your
          own travels?
        </div>
      </div>

      <div className="hero-wrapper">
        <div className="container-slim">
          <div className="hero" />
        </div>
      </div>

      <div className="container-slim">
        <div className="data">
          <div className="left">
            <div className="label UI-subtitle">Team</div>

            <div className="value UI-small1">
              1 UI/UX designer
              <br />1 Full-stack developer
            </div>

            <div className="label UI-subtitle --year">Year</div>

            <div className="value UI-small1">2018 - 2019</div>
          </div>

          <div className="right">
            <div className="UI-subtitle">My role</div>

            <div className="value UI-body">UI/UX Designer</div>

            <div className="tasks UI-small2">
              Guerrilla research & interviews, Proto-personas, Ideation, User-flows, Wireframing,
              Prototyping, User Testing, Visual Design, Brand Identity
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper --grey">
        <div className="container-slim">
          <h3>Validating</h3>

          <p className="UI-body">
            With limited time and no real money to invest, but with certainty on the importance of
            gathering insights to make informed decisions, we decided early on to take a{' '}
            <b>“guerrilla” approach</b> on the research. We started by doing{' '}
            <b>competitive research</b> to see if similar solutions already existed and what they
            offered. We found a few websites and apps that shared similarities with our idea, but
            the products themselves were quite different to what we wanted to offer. This served, at
            some extent, as market validation: these products showed an existence of interested
            people and a ground not fully explored.
          </p>

          <p className="UI-body">
            The next step was to gather <b>online information on tendencies</b> related to our
            potential users: travelers and photographers. We validated our assumption that traveling
            is in an all-time high and that the use of smartphones with better cameras is causing a
            photography boom. We also learnt about which are the countries that travel the most,
            which are their preferred destinations, which countries have the biggest amount of
            photographers, etc.
          </p>

          <ImagesContainerFullWidth
            images={[chartPhoto, chartTravel]}
            disclaimer="Some of the data collected online. Tendencies related to our potential users: travelers and photographers."
          />

          <p className="UI-body">
            The last and more important validation came from <b>talking to potential users</b>. For
            this purpose we contacted and interviewed 5 acquaintances that enjoy photography and
            travel. I led the sessions, that lasted around 20 minutes each. The focus was in
            understanding what was important to them regarding those subjects, what tools they used
            (if any) and what pain points they had. We didn’t hit a saturation point since it was a
            small sample, but the findings were enough to make us decide to move forward. With this
            information, as well as with the information gathered online, I created{' '}
            <b>2 proto-personas</b> to help us guide the rest of our process and decisions.
          </p>

          <ImagesContainerFullWidth
            className="--proto-personas"
            images={[personaLore, personaJan]}
            disclaimer="2 proto-personas I created after conducting interviews and research."
          />
        </div>
      </div>

      <div className="wrapper">
        <div className="container-slim">
          <h3>Ideating</h3>

          <p className="UI-body">
            After concluding the initial research, we embarked on a <b>brainstorming session</b> in
            order to start defining our solution. We came up with many features and functionalities
            that we discussed and organized by themes. After discarding and creating new ideas
            together, we ended up with a solid vision for our product.
          </p>

          <Box
            image={brainstorming}
            disclaimer="Some of the features and fuctionalities that where ideated during the brainstorming
            session."
          />

          <p className="UI-body">
            Nevertheless, we still had to <b>define our MVP’s scope</b>, for which we prioritized
            features basing our decisions on effort and value analysis. At the end we decided to
            focus on 3 key functionalities:
          </p>

          <div className="boxes">
            <div className="boxes__box">
              <h5 className="boxes__title">
                A map in which to manually search for photo locations or spots
              </h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">Collections in which to save those spots</h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">The ability to mark the spots as visited</h5>
            </div>
          </div>

          <p className="UI-body">
            We also decided to make the <b>app available both for Android and iOS</b> by using React
            Native, a framework that allows to develop an app for both platforms through a single
            source code but with non of the performance issues of hybrid apps. This meant that I had
            to design a platform-agnostic app while making sure that interactions and components
            were familiar for both types of users.
          </p>
        </div>
      </div>

      <div className="wrapper --grey">
        <div className="container-slim">
          <h3>Structure & first testing</h3>

          <p className="UI-body">
            With all the previous work ready I started structuring the app: setting its{' '}
            <b>navigation</b>, creating <b>user-flows</b> and <b>sketching</b> screens.
          </p>

          <ThreeBox images={[flow1, flow2]} disclaimer="Some initial sketches and user-flows." />

          <p className="UI-body">
            Staying true to the guerrilla mindset we adopted at the beginning, I decided to turn the
            sketches into a <b>paper prototype</b> in order to do some <b>early testings</b> and
            leverage on the unfinished look to focus on foundational aspects rather than more
            superficial elements.
          </p>

          <BoxFull
            image={prototype}
            disclaimer="The paper prototype used for the initial testings."
          />

          <p className="UI-body">
            The participants were 5 friends, and instead of completing specific tasks I provided
            them with a short scenario and requested them to interact with the prototype freely,
            telling me what they found (or thought) they could do within the app.{' '}
            <b>The results were satisfactory</b>. All of them understood the main goal of the app
            and discovered almost all functionalities (filters, rating, directions and collections).
            The only functionality that proved to be harder to understand was “mark spot as
            visited”, and it was because the icon used didn’t transmit the idea properly. Still,
            after interacting with “profile” and seeing the label “spots visited” next to the icon,
            3 of them understood it.
          </p>
        </div>
      </div>

      <div className="wrapper">
        <div className="container-slim">
          <h3>Putting it all together</h3>
          <p className="UI-body">
            After testing, I continued the process by turning the prototype (an the learnings) into{' '}
            <b>detailed wireframes</b> of the app. It was around this time that I started feeling
            the need of having a <b>brand identity and a small design system</b> in place, so I
            decided to take on that work after finishing the wireframes. I aimed for a sober yet
            modern look and feel that highlighted the beauty of the photo locations but didn’t
            compete with them. The work included brand naming, logotype design and landing page
            design and coding.
          </p>

          <FullWidthImage
            image={mobileWeb}
            disclaimer="The brand identity: naming, logotype and landing page."
          />

          <Box image={final} disclaimer="Some final designs." />

          <div className="design-system">
            <div className="photo">
              <img src={ds1} alt="Design System" />
            </div>
            <div className="photo">
              <img src={ds2} alt="Design System" />
            </div>
            <div className="photo">
              <img src={ds3} alt="Design System" />
            </div>
            <div className="photo">
              <img src={ds4} alt="Design System" />
            </div>
            <div className="photo">
              <img src={ds5} alt="Design System" />
            </div>
            <div className="photo">
              <img src={ds6} alt="Design System" />
            </div>
            <div className="photo">
              <img src={ds7} alt="Design System" />
            </div>
            <div className="photo">
              <img src={ds8} alt="Design System" />
            </div>
          </div>

          <div className="design-system__disclaimer UI-caption">
            The brand identity: design system. Specs and behaviors were given through Zeplin and
            face-to-face communication.
          </div>

          <h4>Final testing</h4>
          <p className="UI-body">
            A key decision we took after having the visual design ready, was to start coding the app
            right away. The reason was that the app hadn’t change substantially since the initial
            testings and that we needed a working prototype to properly test the use of the map.
            With a <b>Beta version</b> in hand, I decided to run <b>unmoderated remote tests</b> in
            order to reach people from other countries. We had 5 participants, and these are some of
            the results:
          </p>

          <div className="boxes --no-bottom-margin">
            <div className="boxes__box">
              <h5 className="boxes__title">
                2 persons commented on finding it a bit tiring to have to tap on a spot at a time to
                see all the locations.
              </h5>

              <div className="boxes__description UI-small1">
                This form of navigation was a basic solution for the MVP, and we knew it would need
                improvement along the way. We decided to leave it as it was for the first release,
                but to work on its improvement soon after.
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">2 persons wondered if they could upload photos.</h5>

              <div className="boxes__description UI-small1">
                This came as a nice surprise since that feature was the next big thing in our
                roadmap. Still, we decided to wait for more data before taking action on it, since
                it wasn’t a minor adding.
              </div>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">1 person was confused by the clusters in the map.</h5>

              <div className="boxes__description UI-small1">
                She didn’t understand why some spots had icons and others had numbers, and wasn’t
                able to interact with them correctly. We decided that the finding wasn’t big enough
                as to take action on it, but pinpointed it as something to keep an eye on during
                future testings.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="wrapper --grey">
        <div className="container-slim">
          <h3>Release & iterations</h3>

          <p className="UI-body">
            Short after releasing the MVP we got the app circulating through small groups of
            photographers and travelers. The first and most frequent question we received once again
            was <b>“can I upload my own photos?”</b> This was the ultimate validation needed to
            start working on the feature right away.
          </p>

          <Video image={uploadImage} video={uploadVideo} disclaimer="Uploader feature" />

          <p className="UI-body">
            By the same time we started working on{' '}
            <b>
              improving the “Explore” section by making it easier to search and navigate its content
            </b>
            . We added a searchbox to look for spots and cities, and added a carousel that appears
            after tapping on a spot and which allows users to swipe through the rest of the content.
          </p>

          <Video image={swipeImage} video={swipeVideo} disclaimer="Searchbox and carousel." />

          <p className="UI-body">
            An odd behavior we noticed through the metrics tracked was that 
            <b>20% of the users abandoned the app in the registration screen</b>. We had 2
            assumptions: 1) they weren’t expecting to have to register and they were put off by
            this, 2) they felt lazy about email registration. Since social media sign up has become
            an expected experience and it was a feature that we already had in our roadmap, we
            decided to begin with that. We have seen the percentage of abandonment reduce to 12%
            since enabling registration through Facebook, which is a nice improvement. Still, we are
            thinking of a solution for those who want to check out the app but don’t want to commit
            to it right away.
          </p>

          <FourPhotos
            images={[registerOld1, registerOld2, registerNew1, registerNew2]}
            disclaimer="Registration screen: old version (only e-mail registration) vs. new version (with social registration)."
          />

          <p className="UI-body">
            Lastly, the <b>biggest adding</b> made to the app since its release, was{' '}
            <b>making it social</b>. This required a change in the information architecture and the
            primary navigation component. The decision was made after seeing users actually upload
            their own photos and assessing that as a sign to finally incorporate the functionality
            and start building a sense of community.
          </p>

          <Video
            className="--social"
            image={socialImage}
            video={socialVideo}
            disclaimer="The social version of the app."
          />
        </div>
      </div>

      <div className="wrapper">
        <div className="container-slim">
          <h3>These days</h3>

          <p className="UI-body">
            It has been over 3 months since the MVP’s release and the app is installed in 300+
            active devices (number of downloads minus number of uninstalls), mostly gained
            organically. We are now <b>focusing on marketing</b> efforts, for getting new users, and
            also <b>working on ways of increasing retention rate.</b>
          </p>

          <p className="UI-body">
            <b>If we were to make this all over again</b>, we would certainly use the “fake door”
            testing for getting a bigger and faster feedback from the market and to start building a
            database of potential users from the beginning. Secondly, we would be more rigorous when
            defining the MVP’s scope, making sure we are not adding tangential features that aren’t
            as valuable as we think and that delay the release.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default NoFilter;
