import React from 'react';
import './styles.scss';

function FourPhotos({ images, disclaimer, className = '' }) {
  return (
    <div className={`FourPhotos ${className}`}>
      <div className="photos">
        <div className="left">
          <div className="photo">
            <img src={images[0]} alt="Infographic" />
          </div>
          <div className="photo">
            <img src={images[1]} alt="Infographic" />
          </div>
        </div>

        <div className="right">
          <div className="photo">
            <img src={images[2]} alt="Infographic" />
          </div>
          <div className="photo">
            <img src={images[3]} alt="Infographic" />
          </div>
        </div>
      </div>

      <div className="disclaimer UI-caption">{disclaimer}</div>
    </div>
  );
}

export default FourPhotos;
