import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import Footer from '../../components/Footer';
import ImagesContainerFullWidth from '../../components/ImagesContainerFullWidth';
import Video from '../../components/Video';
import flow1 from '../../assets/nutripi-sketch2.jpg';
import flow2 from '../../assets/nutripi-sketch1.jpg';
import sectionImage from '../../assets/nutripi-general.png';
import sectionVideo from '../../assets/nutripi-general.mp4';
import overviewImage from '../../assets/nutripi-overview.jpg';
import overviewVideo from '../../assets/nutripi-overview.mp4';
import unlistedImage from '../../assets/nutripi-unlisted-recipe.jpg';
import unlistedVideo from '../../assets/nutripi-unlisted-recipe.mp4';
import waterImage from '../../assets/nutripi-exercise.jpg';
import waterVideo from '../../assets/nutripi-exercise.mp4';
import mealImage from '../../assets/nutripi-recipe.jpg';
import mealVideo from '../../assets/nutripi-recipe.mp4';
import './styles.scss';

function Nutrition({ history }) {
  useEffect(() => {
    if (Cookies.get('isLogged') !== 'yeap') {
      history.push('/');
    }
  });

  return (
    <div className="Nutrition">
      <div className="first-info container-slim">
        <div className="UI-subtitle">Freelance project</div>

        <h2>
          Nutripi
          <br />
          App
        </h2>

        <div className="description UI-highlight">
          An integral service for nutritionist that works through two different but interconnected
          tools: an internal web app for the nutritionist and a mobile app for their patients. I was
          approached to develop the interaction and visual design of the latter.
        </div>
      </div>

      <div className="hero-wrapper">
        <div className="container-slim">
          <div className="hero" />
        </div>
      </div>

      <div className="container-slim">
        <div className="data">
          <div className="left">
            <div className="label UI-subtitle">Team</div>

            <div className="value UI-small1">1 UI/UX designer</div>

            <div className="label UI-subtitle --year">Year</div>

            <div className="value UI-small1">2019</div>
          </div>

          <div className="right">
            <div className="UI-subtitle">My role</div>

            <div className="value UI-body">UI Designer</div>

            <div className="tasks UI-small2">User-flows, Sketching, Visual Design</div>
          </div>
        </div>
      </div>

      <div className="wrapper --grey">
        <div className="container-slim">
          <h3>Understanding</h3>

          <p className="UI-body">
            When the Nutripi team came up to me, <b>they already had a lot of research</b> down
            their belt. After many interviews with nutritionists, they knew their users, their needs
            <b> and had a clear solution in mind</b>.
          </p>

          <p className="UI-body">
            The service that they wanted to offer worked through two different but interconnected
            tools: an internal web app for the nutritionist and a mobile app for their patients. In
            the first one, the nutritionist would put all the data of the patient, along with the
            diet goal and plan. In the second one the patient would interact with all that info and
            track his daily intake and progress. All the info submitted by the patient would be
            automatically available to the nutritionist in the web app, what would allow for an
            easier and closer monitoring, and therefore better results.
          </p>

          <p className="UI-body">
            The mobile app for the patients needed the following <b>functionalities</b>:
          </p>

          <div className="boxes --functionalities --no-bottom-margin">
            <div className="boxes__box">
              <h5 className="boxes__title">
                Be able to see the daily goal and assess the progress
              </h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">Track the food intake</h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">A selection of recipes for each meal of the day </h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">Access to extra recipes</h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">
                The possibility to add food that is not in the recipes
              </h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">
                The possibility to upload a photo of your actual plate of food, for assessment
              </h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">Track daily physical activity</h5>
            </div>

            <div className="boxes__box">
              <h5 className="boxes__title">Track water consumption</h5>
            </div>

            <div className="boxes__box" />
          </div>
        </div>
      </div>

      <div className="wrapper">
        <div className="container-slim">
          <h3>Giving structure</h3>

          <p className="UI-body">
            After understanding the business needs and the users needs, I started <b>sketching</b>{' '}
            the structure of the app and looking for different approaches. With low-fidelity mockups
            in hand I presented to the Nutripi team a{' '}
            <b>proposal with the different user flows and interactions</b>. After discussing the
            designs and doing some little adjustments, I had their go-ahead. Since their decision
            was to do user testings with high fidelity mockups, I started creating the detailed
            visual designs right away.
          </p>

          <ImagesContainerFullWidth
            className="--scketches"
            images={[flow1, flow2]}
            disclaimer="Some of the initial sketches of the app."
          />
        </div>
      </div>

      <div className="wrapper --grey">
        <div className="container-slim">
          <h3 className="--the-app">The app</h3>

          <h4>The 3 main sections</h4>

          <ul>
            <li>
              <p className="UI-body">
                A <b>vertical calendar</b> on the side that allows the user to navigate through the
                current week. Each days that passes gets a green or a red mark, indicating if the
                goal was achieved or not. To see previous weeks or an specific day, you can tap on
                the calendar icon.
              </p>
            </li>

            <li>
              <p className="UI-body">
                An <b>overview area</b> at the top, that allows to quickly see what is the current
                intake of the day and how many calories left to consume you have. By swiping the
                overview down, the user can see the detail of each meal eaten and any exercise done,
                and see how those items add or subtract calories to the total
              </p>
            </li>

            <li>
              <p className="UI-body">
                The <b>tracking section</b>, right below the overview. Here is where the user can
                see the recipes suggested for each meal and mark them as “eaten”. This is also the
                area where he can add any physical activity done during the day and track his water
                consumption.
              </p>
            </li>
          </ul>

          <Video
            image={sectionImage}
            video={sectionVideo}
            disclaimer="The 3 main sections: calendar, overview and tracking area."
          />

          <Video
            image={overviewImage}
            video={overviewVideo}
            disclaimer="How to: access the day details."
          />

          <h4>Recipes and marking them as “eaten”</h4>

          <p className="UI-body">
            The user can navigate through the suggested recipes by swiping left. By tapping on the
            card he can access the recipe, and by tapping the grey button on the image he can mark
            that meal as “eaten”. Once this happens, a camera icon appears giving the user the
            option to upload a photo of the actual plate of food he ate (in case he wants to double
            check with the nutritionist).
          </p>

          <Video
            image={mealImage}
            video={mealVideo}
            disclaimer="How to: access a recipe and mark the meal as eaten."
          />

          <h4>Extra recipes and unlisted ones</h4>

          <p className="UI-body">
            If the user doesn’t want to eat anything from the suggestions, he can tap on the last
            card and access to a screen that allows him to search through extra recipes or to add a
            food that is not listed in the app.
          </p>

          <Video
            image={unlistedImage}
            video={unlistedVideo}
            disclaimer="How to: add an unlisted meal to the app."
          />

          <h4>Exercise and water consumption</h4>

          <p className="UI-body">
            Lastly, users can add any exercise done in the day by selecting from a range of
            activities and time durations. Each activity added will subtract an amount of calories
            from the daily total intake. The water consumption, on the other hand, doesn’t affect
            the diet goal. Its mission is to help the user develop another healthy habit: drinking a
            8-glasses a day.
          </p>

          <Video
            image={waterImage}
            video={waterVideo}
            disclaimer="How to: track exercise and water consumption."
          />
        </div>
      </div>

      <div className="wrapper">
        <div className="container-slim">
          <h3>Next steps</h3>

          <p className="UI-body">
            Since the designs are ready, it’s time for <b>user testing</b>. I’m currently working on
            developing a functional prototype to be used with actual patients of the nutritionists
            interviewed by the Nutripi team. Once the user testings are done, I will be making
            changes and improvements on the design, depending on the findings, and finalizing assets
            for handover to developers.
          </p>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Nutrition;
