import React from 'react';
import './styles.scss';

function Footer() {
  return (
    <div className="Footer">
      <div className="container">
        <a
          className="UI-link"
          href="https://ar.linkedin.com/in/danielamanzotti"
          target="_blank"
          rel="noopener noreferrer"
        >
          LinkedIn
        </a>

        <a className="contact" href="mailto:daniela.manzotti@gmail.com">
          <span className="email UI-link">daniela.manzotti@gmail.com</span>
          <span className="text UI-link">Contact</span>
        </a>
      </div>
    </div>
  );
}

export default Footer;
