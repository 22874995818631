import React from 'react';
import './styles.scss';

function BoxFull({ image, disclaimer }) {
  return (
    <div className="BoxFull">
      <div className="img-wrapper">
        <img className="photo" src={image} alt="Infographic" />
      </div>

      <div className="disclaimer UI-caption">{disclaimer}</div>
    </div>
  );
}

export default BoxFull;
