import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Cookies from 'js-cookie';
import Footer from '../../components/Footer';
import noFilterSrc from '../../assets/nofilter-web-small.png';
import nutriSrc from '../../assets/nutripi-hero-small.png';
import './styles.scss';

function Portfolio({ history }) {
  useEffect(() => {
    if (Cookies.get('isLogged') !== 'yeap') {
      history.push('/');
    }
  });

  return (
    <div className="Portfolio">
      <div className="container-slim">
        <h2>Portfolio</h2>

        <Link to="/portfolio/nofilter">
          <div className="project">
            <div className="data">
              <div className="UI-subtitle">UI/UX Design</div>

              <h3 className="name">
                NoFilter
                <br />
                App
              </h3>

              <div className="description UI-body">
                Creating an app to help photographers and travelers discover amazing photo locations
                around the world
              </div>

              <div className="UI-link">Read case study</div>
            </div>

            <div className="image --nofilter">
              <img src={noFilterSrc} alt="NoFilter screenshot" />
            </div>
          </div>
        </Link>

        <Link to="/portfolio/branch-transformation">
          <div className="project">
            <div className="data">
              <div className="UI-subtitle">UX Design</div>

              <h3 className="name">
                Branch
                <br />
                Transformation
              </h3>

              <div className="description UI-body">
                Working on a phygital project for a major financial company
              </div>

              <div className="UI-link">Read case study</div>
            </div>

            <div className="image --branch" />
          </div>
        </Link>

        <Link to="/portfolio/nutripi">
          <div className="project">
            <div className="data">
              <div className="UI-subtitle">UI Design</div>

              <h3 className="name">
                Nutrition
                <br />
                App
              </h3>

              <div className="description UI-body">
                Designing a nutrition app for professional nutritionist and their patients
              </div>

              <div className="UI-link">Read case study</div>
            </div>

            <div className="image --nutrition">
              <img src={nutriSrc} alt="Nutripi app" />
            </div>
          </div>
        </Link>
      </div>

      <Footer />
    </div>
  );
}

export default Portfolio;
