import React from 'react';
import { Link } from 'react-router-dom';
import './styles.scss';

function Header({ location }) {
  return (
    <div className="container">
      <div className="Header">
        <div className="logo">
          <Link to="/">
            <div className="icon" />
          </Link>

          <Link className="text dani" to="/">
            Daniela Manzotti
          </Link>

          {location.pathname.indexOf('/portfolio') !== -1 && (
            <Link className="text breadcrumb portfolio" to="/portfolio">
              Portfolio
            </Link>
          )}

          {location.pathname === '/portfolio/nofilter' && (
            <Link className="text breadcrumb project" to="/portfolio/nofilter">
              NoFilter App
            </Link>
          )}

          {location.pathname === '/portfolio/branch-transformation' && (
            <Link className="text breadcrumb project" to="/portfolio/branch-transformation">
              Branch Transformation
            </Link>
          )}

          {location.pathname === '/portfolio/nutripi' && (
            <Link className="text breadcrumb project" to="/portfolio/nutripi">
              Nutripi App
            </Link>
          )}
        </div>
      </div>
    </div>
  );
}

export default Header;
