import React, { useState } from 'react';
import { ReactComponent as PadLockIcon } from './../../styles/icons/padlock.svg';
import Cookies from 'js-cookie';
import './styles.scss';

function Home({ history }) {
  const [password, setPassword] = useState('');
  const [isWrongPassword, setIsWrongPassword] = useState(false);

  const handleEnter = () => {
    if (password === 'portfolio123') {
      Cookies.set('isLogged', 'yeap');
      history.push('/portfolio');
    } else {
      setIsWrongPassword(true);
    }
  };

  const alreadySetPassword = Cookies.get('isLogged') === 'yeap';

  return (
    <div className="Home">
      <div className="container">
        <div className="screen">
          <div>
            <h1>
              I’m Daniela, a UI/UX designer from Argentina. Previously at{' '}
              <a
                href="https://www.ibm.com/services/ibmix/"
                target="_blank"
                rel="noopener noreferrer"
              >
                IBM iX
              </a>{' '}
              and{' '}
              <a href="http://www.olapic.com/" target="_blank" rel="noopener noreferrer">
                Olapic
              </a>
              .
            </h1>

            <div className="description UI-highlight">
              I’m a curious and versatile person that found in the tech world the perfect
              convergence of many of her interests. Through user-centered design, I look to shape
              delightful products and experiences.
            </div>

            <div className="links">
              <a className="link UI-link" href={alreadySetPassword ? '/portfolio' : '#access'}>
                Portfolio
              </a>

              <a
                className="link UI-link --linkedin"
                href="https://ar.linkedin.com/in/danielamanzotti"
                target="_blank"
                rel="noopener noreferrer"
              >
                LinkedIn
              </a>

              <a className="link UI-link --email" href="mailto:daniela.manzotti@gmail.com">
                <span className="email">daniela.manzotti@gmail.com</span>
                <span className="contact">Contact</span>
              </a>
            </div>
          </div>
        </div>
      </div>

      {!alreadySetPassword && (
        <div id="access" className="access">
          <div className="container">
            <h3>
              Portfolio <PadLockIcon />
            </h3>

            <div className="description UI-small1">
              This content is password protected, but you can email me for access.
            </div>

            <div className="input-wrapper">
              <form
                action=""
                onSubmit={e => {
                  e.preventDefault();
                  handleEnter();
                }}
              >
                <input
                  type="password"
                  placeholder="Password"
                  value={password}
                  onChange={({ target }) => {
                    setPassword(target.value);
                    setIsWrongPassword(false);
                  }}
                />

                <button onClick={handleEnter}>Enter</button>

                <br />

                {isWrongPassword && (
                  <div className="error UI-caption">Wrong password. Please try again.</div>
                )}
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Home;
