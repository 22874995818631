import React from 'react';
import './styles.scss';

function FullWidthImage({ image, className = '', disclaimer }) {
  return (
    <div className={`FullWidthImage ${className}`}>
      <img src={image} alt="Infographic" />

      <div className="disclaimer UI-caption">{disclaimer}</div>
    </div>
  );
}

export default FullWidthImage;
