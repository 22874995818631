import React from 'react';
import './styles.scss';

function ImagesContainerFullWidth({ images, disclaimer, className = '' }) {
  return (
    <div className={`ImagesContainerFullWidth ${className}`}>
      <div className="photos">
        <img className="photo" src={images[0]} alt="Infographic" />
        <img className="photo" src={images[1]} alt="Infographic" />
      </div>

      <div className="disclaimer UI-caption">{disclaimer}</div>
    </div>
  );
}

export default ImagesContainerFullWidth;
