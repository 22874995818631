import React from 'react';
import './styles.scss';

function Box({ image, disclaimer }) {
  return (
    <div className="Box">
      <div className="img-wrapper">
        <div className="img">
          <img className="photo" src={image} alt="Infographic" />
        </div>
      </div>

      <div className="disclaimer UI-caption">{disclaimer}</div>
    </div>
  );
}

export default Box;
