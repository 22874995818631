import React from 'react';
import './styles.scss';

function Video({ image, video, disclaimer, className = '' }) {
  return (
    <div className={`Video ${className}`}>
      <div className="Video__wrapper">
        <div className="Video__media">
          <div className="Video__image">
            <img src={image} alt="Infographic" />
          </div>

          <div className="Video__video">
            <video muted controls>
              <source src={video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>
      </div>

      <div className="Video__disclaimer">{disclaimer}</div>
    </div>
  );
}

export default Video;
