import React from 'react';
import './styles.scss';

function ThreeBox({ images, disclaimer }) {
  return (
    <div className="ThreeBox">
      <div className="ThreeBox__wrapper">
        <div className="ThreeBox__photos">
          <div className="ThreeBox__left">
            <img src={images[0]} alt="Infographic" />
          </div>
          <div className="ThreeBox__right">
            <img src={images[1]} alt="Infographic" />
          </div>
        </div>
      </div>

      <div className="ThreeBox__disclaimer">{disclaimer}</div>
    </div>
  );
}

export default ThreeBox;
